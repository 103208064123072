import React from "react";
import heroImg from "./../../assets/png/hero.webp";
import { useState } from "react";
import { Copyicon } from "../../helper/Icon";

function Hero() {
  const [copied, setCopied] = useState(false);
  const textToCopy = "m6gG3z28NjKQn1GyEUjFEKTQnFmCeP2RLFYRphTJoSH";

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <>
      <div className="max-w-[1400px] px-4 sm:px-6 lg:px-8 xl:px-10  w-full mx-auto hero-bg ">
        <div className="  py-10   text-center flex flex-col">
          <h1 className="heading text-white text-4xl sm:text-6xl md:text-7xl xl:text-9xl font-gliker-semi-bold uppercase">
            $SUIbara
          </h1>
          <div className="max-w-[300px] md:max-w-[400px] lg:max-w-[500px] xl:max-w-[600px] mx-auto my-3">
            <img src={heroImg} alt="img" className="w-full h-auto" />
          </div>
          <div className="flex justify-center">
            <button className="bg-secondary shadow-[0px_4px_48px_0px] shadow-[rgba(0,0,0,0.40)] border border-[#050203] rounded-full py-4 md:py-6 lg:py-7 xl:py-9 max-w-[180px] md:max-w-[230px] lg:max-w-[260px] xl:max-w-[300px] mx-auto text-white text-base md:text-lg xl:text-xl font-super w-full transition-all duration-300 ease-in-out hover:shadow-secondary uppercase tracking-[1px]">
              Buy Now
            </button>
          </div>
          <div className="max-w-[1030px] sm:mx-auto flex flex-col gap-2 lg:gap-4 mt-5 md:mt-7 lg:mt-10 xl:mt-12 text-start w-[90%] sm:w-full">
            <p className="text-black text-base sm:text-lg md:text-xl lg:text-2xl uppercase  tracking-[0.4px] font-super ">
              sui CONTRACT ADDRESS
            </p>
            <div className="flex items-center ">
              <div className="bg-white rounded-full border md:border-[3px] lg:border-[6px] border-black px-2 sm:px-4 xl:px-6  py-4 xl:py-7 w-full  ">
                <p className="text-sm sm:text-base md:text-lg lg:text-2xl xl:text-3xl  text-black text-ellipsis overflow-hidden  truncat uppercase    text-center  font-gliker-noraml  ">
                  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                </p>
              </div>
              <button
                className="bg-white border md:border-[2px] lg:border-[4px] border-black rounded-full transition-all duration-300 ease-in-out min-w-14 h-14 lg:min-w-20 lg:h-20  xl:h-24 xl:min-w-24 flex flex-col justify-center items-center -ml-6 text-sm font-semibold "
                onClick={handleCopy}
              >
                {copied ? "Copied!" : <Copyicon />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
