import React from "react";
import { howToBuyData } from "../../helper/Helper";
import SliderCard from "./../../common/Buycard";
import swap from "./../../assets/png/swap.png";
function HowToBuy() {
  return (
    <>
      <div
        id="How_to_Buy"
        className=" bg-secondary  py-12 md:py-16 lg:py-20 xl:py-28 w-full"
      >
        <div className="max-w-[1680px] px-4 sm:px-6 lg:px-8 xl:px-10  w-full mx-auto">
          <h2 className=" text-4xl sm:text-5xl md:text-6xl xl:text-7xl   text-white sub-heading font-gliker-semi-bold   text-center uppercase ">
            HOW TO BUY $suibara
          </h2>
          <div className="flex flex-wrap   gap-3 mt-5 lg:mt-8 xl:mt-16 sm:pt-5 justify-center lg:justify-between">
            {howToBuyData.map((item, index) => (
              <SliderCard
                key={index}
                img={item.img}
                number={item.number}
                title={item.title}
                dis={item.dis}
              />
            ))}{" "}
            <div
              className="lg:mt-8  bg-primary border md:border-[2px] lg:border-[4px] border-black rounded-[32px]  
      p-3 md:p-4 lg:p-7 xl:p-10 w-full sm:w-[48%]  lg:w-full max-w-[507px] sm:max-w-full   flex  flex-col lg:items-center lg:flex-row gap-6 lg:gap-12  gap-y-4 md:gap-y-7 lg:gap-y-10 xl:gap-y-20"
            >
              <h3 className="text-black number  font-gliker-semi-bold text-3xl md:text-5xl xl:text-6xl">
                04
              </h3>
              <div>
                <p className="py-1 sm:py-2 mt-2 text-black font-gliker-bold text-base sm:text-lg lg:text-xl  uppercase xl:text-xl tracking-[2.2px] ">
                  {" "}
                  Swap SUI for Suibara Coins
                </p>
                <p className="text-black font-gliker-semi-bold text-sm sm:text-base lg:text-lg  tracking-[1.3px] ">
                  {" "}
                  Navigate to the swap section and trade your SUI for CapyCoins.
                </p>
              </div>
              <div className="mb-2 xl:mb-4 max-w-20 sm:max-w-[120px] lg:max-w-[167px] ml-auto">
                <img
                  src={swap}
                  alt="img"
                  className="w-full h-auto  shadow-[5.473px_4.447px_39.681px_0px_rgba(111,95,0,0.50)] rounded-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToBuy;
