import React from "react";

function Buycard({ number, title, dis, img }) {
  return (
    <>
      <div
        className="max-w-[507px] bg-primary border md:border-[2px] lg:border-[4px] border-black rounded-[32px]  
      p-3 md:p-4 lg:p-7 xl:p-10 w-full sm:w-[48%] lg:w-[32%]   flex flex-col justify-between gap-y-4 md:gap-y-7 lg:gap-y-10 xl:gap-y-20"
      >
        <div>
          <h3 className="text-black number  font-gliker-semi-bold text-3xl md:text-5xl xl:text-6xl">
            {number}
          </h3>
          <p className="py-1 sm:py-2 mt-2 text-black font-gliker-bold text-base sm:text-lg lg:text-xl  uppercase xl:text-xl tracking-[2.2px] ">
            {title}
          </p>
          <p className="text-black font-gliker-semi-bold text-sm sm:text-base lg:text-lg  tracking-[1.3px] ">
            {dis}
          </p>
        </div>

        <div className="mb-2 xl:mb-4 max-w-20 sm:max-w-[120px] xl:max-w-[167px] ml-auto">
          <img src={img} alt="img" className="w-full h-auto" />
        </div>
      </div>
    </>
  );
}

export default Buycard;
