import React, { useEffect, useState } from "react";
import logoimg from "./../../components/assets/png/logo.png";
import { mediaLink, navLinks } from "../helper/Helper";
import Sidebar from "./Sidebar";
import { HamburgerIcon } from "../helper/Icon";
function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;
    if (isOpen) {
      body.style.overflow = "hidden";
      html.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    };
  }, [isOpen]);
  return (
    <>
      <div className="py-5 md:py-7 xl:py-10    ">
        <div className="max-w-[1400px] px-4 sm:px-6 lg:px-8 xl:px-10  w-full mx-auto">
          <div className="bg-white border md:border-2 border-black rounded-2xl shadow-custom p-2 md:p-3  xl:p-4 flex flex-wrap sm:flex-row justify-between items-center gap-4">
            <div className=" ">
              <a href="#" className="flex items-center gap-2 xl-gap-3">
                <div className="border rounded border-black shadow-custom  p-1 max-w-[40px] md:max-w-[52px] w-full">
                  <img
                    src={logoimg}
                    alt="logo"
                    width={100}
                    height={100}
                    className="w-full h-auto"
                  />
                </div>
                <span className="text-[#262D3F] text-base md:text-xl font-super tracking-[0.4px]">
                  Suibara
                </span>
              </a>
            </div>
            <div className="gap-4 xl:gap-6   flex-row  justify-center hidden sm:flex">
              {navLinks.map((item, index) => (
                <a
                  key={index}
                  href={item.url}
                  className="text-sm md:text-base font-super text-[#262D3F] transition-all duration-300 ease-in-out hover:text-secondary"
                >
                  {item.title}
                </a>
              ))}
            </div>
            <div className="flex items-center gap-2  ">
              {mediaLink.map((item, index) => (
                <a
                  key={index}
                  href={item.url}
                  target="_blank"
                  className="border rounded border-black shadow-custom h-10 w-10  md:w-12 md:h-12 flex flex-col justify-center items-center transition-all duration-300 ease-in-out hover:shadow-secondary hover:border-secondary group "
                >
                  {item.icon}
                </a>
              ))}
              <button className="sm:hidden" onClick={toggleOpen}>
                <HamburgerIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed top-0 left-0 w-full h-full bg-secondary sm:bg-transparent transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <Sidebar toggleOpen={toggleOpen} />
      </div>
    </>
  );
}

export default Navbar;
