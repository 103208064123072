import React from "react";
import Navbar from "../../common/Navbar";
import Hero from "./Hero";
import Footer from "../../common/Footer";
import Slider from "../../common/Slider";
import WhoAreWe from "./WhoAreWe";
import Tokenomic from "./Tokenomic";
import HowToBuy from "./HowToBuy";
import Roadmap from "./Roadmap";

function HomePage() {
  return (
    <>
      <Navbar />
      <Hero />
      <Slider />
      <WhoAreWe />
      <Slider />
      <Tokenomic />
      <Slider />
      <HowToBuy />
      <Slider />
      <Roadmap />
      <Slider />
      <Footer />
    </>
  );
}

export default HomePage;
