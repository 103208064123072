import React from "react";
import swap from "./../assets/png/swap.png";
import { footermedia } from "../helper/Helper";
import { Thumbicon } from "../helper/Icon";
function Footer() {
  return (
    <>
      <div className="footer-bg xl:py-10">
        <div className="  py-12 md:py-16 lg:py-20 xl:py-28 w-full">
          <div className="max-w-[1680px] px-4 sm:px-6 lg:px-8 xl:px-10  w-full mx-auto ">
            <div className="flex md:flex-row flex-col justify-center md:justify-between gap-6">
              <div className="max-w-[120px] md:max-w-[390px] mx-auto md:mx-0 flex flex-col gap-4 md:gap-7  xl:gap-12">
                <a
                  href="#"
                  className="w-full max-w-[287px] mx-auto rounded-full shadow-[16px_13px_116px_0px_rgba(111,95,0,0.50)]"
                >
                  <img src={swap} alt="logo" className="w-full rounded-full" />
                </a>
                <a
                  href="#"
                  className="text-black font-gliker-normal-2 text-center text-base sm:text-lg lg:text-2xl xl:text-4xl"
                >
                  $Suibara
                </a>
              </div>
              <div className="max-w-[948px] flex flex-col">
                <p className="font-gliker-normal-2 text-black text-base sm:text-lg lg:text-xl xl:text-2xl  ">
                  Join our growing community on Discord, Twitter, and Telegram
                  to stay updated, share memes, and participate in fun events!
                </p>
                <div className="mt-4 sm:mt-6 lg:mt-10 xl:mt-16">
                  <a
                    href="#"
                    className="communituy-bg font-gliker-normal-2 text-black text-sm sm:text-lg lg:text-xl xl:text-2xl max-w-[300px] sm:max-w-[400px]  lg:max-w-[521px] h-20 lg:h-[115px] w-full flex flex-col justify-center items-center pt-3 transition-all duration-300 ease-in-out hover:scale-95 "
                  >
                    Join the communituy
                  </a>
                </div>
              </div>
              <div className=" md:max-w-[90px] xl:max-w-[100px] w-full flex md:flex-col h-full items-end justify-between">
                <div className="bg-white border md:border-2 border-black rounded-full flex md:flex-col   gap-2     xl:gap-4 lg:gap-5 p-3  justify-center items-center h-full">
                  {footermedia.map((item, index) => (
                    <a
                      key={index}
                      href={item.url}
                      className="w-14 h-14 sm:w-16 sm:h-16 xl:w-[72px] xl:h-[72px] border border-black rounded-full flex flex-col justify-center items-center shadow-custom  hover:shadow-secondary hover:border-secondary group
                   "
                    >
                      {item.icon}
                    </a>
                  ))}
                </div>

                <div className="mt-6 flex justify-end">
                  <Thumbicon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
