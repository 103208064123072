import React from "react";
import SliderCard from "./../../common/SliderCard";
import { tokenomicData } from "../../helper/Helper";
function Tokenomic() {
  return (
    <>
      <div id="Tokenomics" className="py-12 md:py-16 lg:py-20 xl:py-28 w-full">
        <div className="max-w-[1680px] px-4 sm:px-6 lg:px-8 xl:px-10  w-full mx-auto">
          <h2 className=" text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-[120px] text-white   font-gliker-semi-bold heading text-center uppercase">
            tokenomics
          </h2>
          <div className="flex flex-wrap lg:flex-nowrap gap-3 my-5 lg:mt-8 xl:mt-16 sm:pt-5 justify-center lg:justify-between">
            {tokenomicData.map((item, index) => (
              <SliderCard key={index} img={item.img} value={item.value} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Tokenomic;
