import React from "react";
import aboutimg from "./../../assets/png/about.png";
function WhoAreWe() {
  return (
    <>
      <div
        id="About"
        className="bg-secondary py-12 md:py-16 lg:py-20 xl:py-28 w-full"
      >
        <div className="max-w-[1680px] px-4 sm:px-6 lg:px-8 xl:px-10  w-full mx-auto">
          <div className="flex flex-col lg:flex-row justify-between gap-6 items-center">
            <div className="max-w-[400px] md:max-w-[500px] lg:max-w-[740px] ">
              <img src={aboutimg} alt="img" className="w-full h-auto" />
            </div>
            <div className="lg:max-w-[745px]">
              <h2 className=" text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-[120px] text-white sub-heading font-gliker-semi-bold ">
                Who <br className="hidden lg:block" /> We Are
              </h2>
              <p className="text-white text-base sm:text-lg lg:text-xl  xl:text-[30px] font-gliker-medium  mt-4 md:mt-7 xl:mt-10 !leading-[130%] lg:tracking-[1.9px]">
                Suibara is a fun and innovative meme coin that celebrates the
                lovable Suibara, known for its friendly demeanor and social
                nature. We believe in building a community where everyone can
                enjoy the lighter side of cryptocurrency while making meaningful
                connections.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhoAreWe;
