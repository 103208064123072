import React from "react";
import RoadmapCard from "../../common/RoadmapCard";
import { roadmapData } from "../../helper/Helper";

function Roadmap() {
  return (
    <>
      <div className="  py-12 md:py-16 lg:py-20 xl:py-28 w-full">
        <div className="max-w-[1680px] px-4 sm:px-6 lg:px-8 xl:px-10  w-full mx-auto lg:pb-20">
          <h2 className=" text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-[100px] text-white   font-gliker-semi-bold heading text-center uppercase ">
            roadmap
          </h2>
          <div className="flex flex-wrap  gap-y-16  gap-3  mt-16  pt-5 justify-center lg:justify-between">
            {roadmapData.map((item, index) => (
              <RoadmapCard
                key={index}
                title={item.title}
                number={item.number}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Roadmap;
