import { Metaicon, TeligramIcon, TwitterIcon } from "./Icon";
import cap from "./../assets/png/cap.png";
import contract from "./../assets/png/contract.png";
import lp from "./../assets/png/lp.png";
import caplp from "./../assets/png/caplp.png";

import wallet from "./../assets/png/wallet.png";
import sui from "./../assets/png/sui.png";
import connect from "./../assets/png/connect.png";
export const navLinks = [
  { title: "Home", url: "/" },
  { title: "About", url: "#About" },
  { title: "Tokenomics", url: "#Tokenomics" },
  { title: "How to Buy", url: "#How_to_Buy" },
];

export const mediaLink = [
  { icon: <TwitterIcon />, url: "https://x.com/home" },
  { icon: <TeligramIcon />, url: "https://web.telegram.org" },
];
export const sliderData = [
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
  { title: "$suibara" },
];
export const tokenomicData = [
  { img: cap, value: "1,000,000,000 $CAP" },
  { img: contract, value: "Contract renounced" },
  { img: lp, value: "LP Burned" },
  { img: caplp, value: "100% $CAP LP" },
];
export const howToBuyData = [
  {
    number: "01",
    img: wallet,
    title: "Create a wallet",
    dis: "Ensure you have a compatible wallet (e.g., Sui Wallet).",
  },
  {
    number: "02",
    img: sui,
    title: "Acquire SUI Tokens",
    dis: "Purchase SUI tokens on a supported exchange.",
  },
  {
    number: "03",
    img: connect,
    title: "Connect Your Wallet",
    dis: "Go to our website and connect your wallet",
  },
];
export const roadmapData = [
  {
    number: "01",
    title: "branding & creation, build the community, initial marketing",
  },
  {
    number: "02",
    title: "launch, partnerships, cmc & cg listings, community engagement",
  },
  {
    number: "03",
    title: "marketing push, sustainable growth, on-boarding kols",
  },
];
export const footermedia = [
  { icon: <TeligramIcon />, url: "https://web.telegram.org" },
  { icon: <TwitterIcon />, url: "https://x.com/home" },
  { icon: <Metaicon />, url: "" },
];
