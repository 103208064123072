import "./App.css";
import "./Stylesheet.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomePage from "./components/page/home/HomePage";
function App() {
  return (
    <>
      <HomePage />
    </>
  );
}

export default App;
