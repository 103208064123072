import React from "react";
import { sliderData } from "../helper/Helper";
import Slider from "react-slick";

function Sliders() {
  var settings = {
    autoplay: true,
    autoplaySpeed: 0,
    speed: 6000,
    arrows: false,
    centerMode: false,
    slidesToShow: 9,
    slidesToScroll: 1,
    cssEase: "linear",
    infinite: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <>
      <div className="border-y sm:border-y-2 lg:border-y-4 border-white bg-black py-3 sm:py-4 lg:py-5 xl:py-6 w-full text-white">
        <Slider {...settings}>
          {sliderData.map((item, index) => (
            <p
              key={index}
              className="text-[#EDECF3] text-xl sm:text-2xl md:text-3xl lg:text-4xl font-super uppercase leading-[100%] mt-1.5"
            >
              {item.title}
            </p>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default Sliders;
