import React from "react";
import ff from "./../assets/png//topcard-bgs.png";
import { Circelicon, Clipicon } from "../helper/Icon";
function RoadmapCard({ number, title }) {
  return (
    <>
      <div className="max-w-[490px] card-bg   rounded-lg  p-3 md:p-4 lg:p-6 xl:p-8 w-full sm:w-[48%] lg:w-[32%]   flex flex-col justify-center items-center gap-y-6 md:gap-y-8 lg:gap-y-10 relative z-[-2] min-h-[300px] sm:min-h-[400px]  !pe-5 sm:!pe-10">
        <div className="absolute left-[5%] sm:left-[10%] top-[-16px] max-w-4">
          <Clipicon />
        </div>
        <div className="absolute -top-8 ">
          <div className="max-w-[180px] xl:max-w-[226px] w-full relative h-16 md:h-20 xl:h-24   mx-auto flex flex-col justify-center items-center ">
            <img src={ff} alt="img" className="w-full h-auto " />

            <span className="text-black font-gliker-semi-bold text-base  sm:text-lg xl:text-3xl absolute rotate-[15deg] ">
              {number}
            </span>
          </div>
        </div>
        <div className="flex gap-2 gap-y-3 w-full">
          <div className="w-8">
            <Circelicon />
          </div>
          <p className="text-white text-base sm:text-lg lg:text-xl font font-gliker-semi-bold capitalize tracking-[1.5px]">
            {" "}
            {title}
          </p>
        </div>
      </div>
    </>
  );
}

export default RoadmapCard;
